.custom-gradient {
  background: linear-gradient(
      90deg,
      rgba(96, 202, 84, 0.2) 1.72%,
      rgba(0, 0, 0, 0) 26.54%
    ),
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.07) 0%,
      rgba(255, 255, 255, 0.07) 100%
    );
}

.custom-gradient.dark {
  background-color: #0b2233;
}

.custom-gradient.light {
  background-color: #fff;
}
